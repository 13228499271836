import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { ComponentsModule } from '@mydendra/components/components.module';
import { SharedComponentsModule } from '@shared/components';
import { SharedDirectivesModule } from '@shared/directives';
import { SharedPipesModule } from '@shared/pipes';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { HomeProgressBarComponent } from './home-page/components/progress-bar/home-progress-bar.component';
import { ProjectSummaryMapComponent } from './home-page/components/project-summary/project-summary-map.component';
import { ProjectSummaryComponent } from './home-page/components/project-summary/project-summary.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HomeRoutingModule } from './home-routing.module';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    HomeRoutingModule,
    NgxMapLibreGLModule,
    SharedPipesModule,
    TooltipModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    HomePageComponent,
    ProjectSummaryComponent,
    HomeProgressBarComponent,
    ProjectSummaryMapComponent,
  ],
})
export class HomeModule {}
