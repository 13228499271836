<div class="progress__classification">
  <span class="justify-content-end align-items-middle body-sm d-flex"
    >{{ title }}
    @if (description) {
      <mydendra-icon
        name="info-tooltip"
        size="xs"
        [tooltip]="description"
        container="body"
      ></mydendra-icon>
    }
  </span>
  <div class="progress">
    <div
      class="progress-bar"
      [style.width]="completionPercentage + '%'"
      [class]="progressBarClassName"
    ></div>
    <div class="progress-label" [class.progress-label--empty]="!current">
      {{ label }}
    </div>
  </div>
</div>
