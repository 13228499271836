import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedComponentsModule } from '@shared/components';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'mydendra-home-progress-bar',
  templateUrl: './home-progress-bar.component.html',
  styleUrls: ['./home-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipModule, SharedComponentsModule],
})
export class HomeProgressBarComponent {
  @Input() current: number;
  @Input() end: number;
  @Input() label: string;
  @Input() title: string;
  @Input() bg?: string;
  @Input() description?: string;

  get completionPercentage() {
    return Math.round((this.current / this.end) * 100);
  }

  get progressBarClassName() {
    return this.bg ? `bg-${this.bg}` : '';
  }
}
