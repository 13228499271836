<div class="home__panel">
  <div class="home__project-header">
    <h3 class="d-flex heading-3 align-items-center mb-0">
      <mydendra-icon class="mr-xxs" name="globe"></mydendra-icon
      >{{ project.name }}
    </h3>
    <mydendra-button
      *ifHasFlag="'monitoring-app'; else mapsButton"
      icon="overview"
      type="light"
      text="View in Monitoring"
      icon_position="left"
      [routerLink]="['/monitor', project.id]"
    ></mydendra-button>
  </div>
  <div>
    <div class="d-flex">
      <div class="map-container">
        <home-project-summary-map
          class="h-100 w-100 d-block"
          [locationCollection]="project"
        ></home-project-summary-map>
      </div>
      <div class="home__statistic-group">
        <div class="section">
          <small class="section__title subtitle-xs">
            Project information
          </small>
          <mydendra-icon
            class="section__info-icon"
            color="primary"
            name="info-tooltip"
            size="xs"
            tooltip="Aggregate top-level statistics"
          ></mydendra-icon>
        </div>
        <div class="statistic">
          <div class="mt-xxs subtitle-bold">{{ project.area | area }}</div>
          <small class="body-sm text-evaluation"> Managed Area </small>
        </div>
        <div class="statistic">
          <div class="d-flex align-items-center mt-xxs home__insight-list">
            @for (category of project.available_categories; track category) {
              <mydendra-icon
                [name]="category.icon"
                color="primary"
                size="lg"
                [tooltip]="category.title"
                container="body"
                class="category-icon"
              ></mydendra-icon>
            }
          </div>
          <small class="body-sm text-evaluation">Available Insights</small>
        </div>
      </div>

      <ng-container *ifHasFlag="'show-location-classification'">
        <div class="home__statistic-group">
          <div class="d-flex">
            <small class="text-muted"
              >RestorationOS
              <span class="text-uppercase">Classification</span>
            </small>
          </div>
          @for (
            classification of locationsByClassification$ | async;
            track classification
          ) {
            <div class="home__classification">
              <mydendra-home-progress-bar
                [title]="classification.classification | titlecase"
                class="flex-grow-1"
                [current]="classification.area"
                [end]="project.area"
                [label]="
                  classification.area ? (classification.area | area) : '-'
                "
                [description]="classification.description"
              ></mydendra-home-progress-bar>
            </div>
          }
        </div>
      </ng-container>
      @for (metric of aggregateMetrics$ | async; track metric) {
        <div class="home__statistic-group">
          <mydendra-metrics-chart
            [data]="metric.data"
            [title]="supportedMetrics[metric.metric_type].chartTitle"
            [icon]="supportedMetrics[metric.metric_type].icon"
          ></mydendra-metrics-chart>
        </div>
      }
    </div>
    <div class="home__summary-grid">
      <ng-container *ifHasFlag="'intervention-app'">
        @if (interventionSummary$ | async; as interventionSummary) {
          @if (interventionSummary.total > 0) {
            <mydendra-summary-card
              icon="route-seeding"
              actionIcon="chevron-right"
              title="Interventions"
              [cardHeader]="
                interventionSummary.completed +
                (interventionSummary.completed
                  | i18nPlural
                    : {
                        '=1': ' intervention completed',
                        other: ' interventions completed',
                      })
              "
              [routerLink]="['/intervention', project.id]"
            >
              <div
                class="d-flex justify-content-between align-items-center mb-xxs"
              >
                <div class="body">
                  {{ interventionSummary.completed }}/{{
                    interventionSummary.total
                  }}
                </div>
                <div class="d-flex text-evaluation body-sm align-items-center">
                  {{
                    interventionSummary.completed / interventionSummary.total
                      | percent
                  }}
                  completed
                  <mydendra-icon
                    name="info-tooltip"
                    size="xs"
                    tooltip="Percentage of interventions completed compared with pending."
                    container="body"
                  ></mydendra-icon>
                </div>
              </div>
              <mydendra-progress-bar
                [current]="interventionSummary.completed"
                [end]="interventionSummary.total"
                [slim]="true"
              ></mydendra-progress-bar>
            </mydendra-summary-card>
          }
        }
      </ng-container>
      @if (latestReport$ | async; as latestReport) {
        <mydendra-summary-card
          icon="report"
          actionIcon="download-export"
          title="Latest Report"
          [cardHeader]="latestReport.title"
          [externalLink]="latestReport.report_link"
          [theme]="'dark'"
        >
          <p class="text-white">
            View the latest report from Dendra on your project's restoration
            journey.
          </p>
        </mydendra-summary-card>
      }
    </div>
  </div>
</div>

<ng-template #mapsButton>
  <mydendra-button
    icon="gis"
    type="light"
    text="View in Maps"
    icon_position="left"
    [routerLink]="['/maps', project.id]"
  ></mydendra-button>
</ng-template>
