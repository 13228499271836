@if (data$ | async; as data) {
  <mydendra-map-container
    label="Areas of Interest"
    class="home-project-summary-map"
  >
    @if (!data.loading) {
      <mgl-map
        [fitBounds]="data.bounds"
        [fitBoundsOptions]="fitBoundsOpts"
        [style]="style$ | async"
        [attributionControl]="false"
        [interactive]="false"
      >
        @for (boundary of data.boundaries; track $index) {
          <mydendra-boundary-layer
            [boundary]="boundary.boundary"
            [layerId]="'boundary-' + boundary.id"
            [paintProps]="paintProps"
          ></mydendra-boundary-layer>
        }
      </mgl-map>
    } @else {
      <mydendra-loading [in_container]="false"></mydendra-loading>
    }
  </mydendra-map-container>
  <ng-template #loading>
    <mydendra-loading [in_container]="false"></mydendra-loading>
  </ng-template>
}
