import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { StylesInteractor } from '@dendra/interactor-styles';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { BOUNDARY_LAYER_PAINT_PROPS } from '@mydendra/components/boundary-layer/boundary-layer.component';
import { ComponentsModule } from '@mydendra/components/components.module';
import { SMALL_FIT_BOUNDS_OPTS } from '@mydendra/portal/constants';
import { toBBox2d } from '@mydendra/utils/formats';
import { captureException } from '@sentry/browser';
import {
  LocationCollection,
  LocationCollectionService,
  type AggregateBoundary,
} from '@services/states/location-collection';
import { SharedComponentsModule } from '@shared/components';
import { feature, featureCollection } from '@turf/helpers';
import { LngLatBoundsLike } from 'maplibre-gl';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  tap,
} from 'rxjs';

const PAINT_PROPS = { ...BOUNDARY_LAYER_PAINT_PROPS, 'line-color': '#FF1493' };

@Component({
  selector: 'home-project-summary-map',
  templateUrl: './project-summary-map.component.html',
  styleUrls: ['./project-summary-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgxMapLibreGLModule,
    SharedComponentsModule,
    ComponentsModule,
  ],
})
export class ProjectSummaryMapComponent implements OnInit {
  private locationCollectionService = inject(LocationCollectionService);
  private stylesInteractor = inject(StylesInteractor);
  private loadingBounds = new BehaviorSubject(true);

  @Input() locationCollection!: LocationCollection;

  data$: Observable<{
    loading: boolean;
    bounds: LngLatBoundsLike;
    boundaries: AggregateBoundary[];
  }>;

  style$ = this.stylesInteractor.selectBaseStyles();

  ngOnInit(): void {
    const boundaries$ = this.locationCollectionService
      .getAggregateBoundaries(this.locationCollection.id)
      .pipe(
        catchError(err => {
          captureException(err);
          return of(null);
        }),
        tap(() => this.loadingBounds.next(false)),
        shareReplay(1),
      );
    this.data$ = combineLatest({
      boundaries: boundaries$.pipe(startWith(null)),
      bounds: boundaries$
        .pipe(
          map(boundaries =>
            boundaries.length ? toBBox2d(this.unionCoords(boundaries)) : null,
          ),
        )
        .pipe(startWith(null)),
      loading: this.loadingBounds.asObservable(),
    });
  }

  unionCoords(boundaries: AggregateBoundary[] = []) {
    return featureCollection(boundaries.map(b => feature(b.boundary)));
  }

  fitBoundsOpts = SMALL_FIT_BOUNDS_OPTS;
  paintProps = PAINT_PROPS;
}
