import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { sumByKey } from '@dendra/utils';
import { ComponentsModule } from '@mydendra/components/components.module';
import { MetricService } from '@mydendra/monitoring/services/metrics.service';
import {
  aggregateMetricResultByYear,
  MetricResult,
  supportedMetrics,
} from '@mydendra/monitoring/services/metrics.utils';
import {
  InterventionService,
  InterventionSummary,
} from '@mydendra/states/intervention';
import {
  Location,
  LocationService,
  RestorationOSClassification,
} from '@mydendra/states/location';
import { Report, ReportService } from '@mydendra/states/report';
import {
  CategoryConfig,
  InsightType,
} from '@mydendra/utils/insight-categories';
import { LocationCollection } from '@services/states/location-collection';
import { SharedComponentsModule } from '@shared/components';
import { SharedDirectivesModule } from '@shared/directives';
import { SharedPipesModule } from '@shared/pipes';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { map, Observable, Subject } from 'rxjs';
import { HomeProgressBarComponent } from '../progress-bar/home-progress-bar.component';
import { ProjectSummaryMapComponent } from './project-summary-map.component';

export type LocationsByClassification = {
  classification: string;
  area: number;
  description: string;
};

const CLASSIFICATON_TOOLTIP = {
  [RestorationOSClassification.RESTORATION]:
    'Area where the population has been largely eradicated and where successful afforestation is likely to come with greater program risk',
  [RestorationOSClassification.REHABILITATION]:
    'Area where the population has been degraded and would offer a significant and low risk opportunity for active regeneration',
  [RestorationOSClassification.CONSERVATION]:
    'Area where the population appears healthy and a plan should be defined to ensure that these areas are protected from future potential damage',
};

type AnnotatedLocationCollection = LocationCollection & {
  available_categories: CategoryConfig<InsightType>[];
};

@Component({
  selector: 'home-project-summary-card',
  templateUrl: './project-summary.component.html',
  styleUrls: ['./project-summary.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    TooltipModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    ComponentsModule,
    ProjectSummaryMapComponent,
    HomeProgressBarComponent,
  ],
})
export class ProjectSummaryComponent implements OnInit {
  private locationService = inject(LocationService);
  private metrics = inject(MetricService);
  private reportService = inject(ReportService);
  private interventionService = inject(InterventionService);

  @Input() project: AnnotatedLocationCollection;
  private locations$ = new Subject<Location[]>();
  private metrics$ = new Subject<MetricResult>();
  latestReport$ = new Subject<Report>();

  interventionSummary$: Observable<InterventionSummary>;

  locationsByClassification$ = this.locations$.pipe(
    map(locations => {
      const grouped = Object.groupBy(locations, loc => loc.classification);
      return Object.keys(RestorationOSClassification).map(key => {
        const label = RestorationOSClassification[key];
        return {
          classification: label,
          area: grouped[label] ? sumByKey(grouped[label], 'area') : 0,
          description: CLASSIFICATON_TOOLTIP[label],
        };
      });
    }),
  );

  aggregateMetrics$ = this.metrics$.pipe(map(aggregateMetricResultByYear));

  ngOnInit(): void {
    this.locationService
      .getForLocationCollection(this.project.id)
      .subscribe(locations => this.locations$.next(locations));
    this.metrics
      .getForLocationCollection(this.project.id)
      .subscribe(metrics => this.metrics$.next(metrics));
    this.reportService
      .getLatestReportForCollection(this.project.id)
      .subscribe(report => this.latestReport$.next(report));
    this.interventionSummary$ =
      this.interventionService.getSummaryForCollection(this.project.id);
  }

  supportedMetrics = supportedMetrics;
}
