import { Injectable, inject } from '@angular/core';
import {
  EntityState,
  EntityStore,
  Order,
  QueryConfig,
  QueryEntity,
  StoreConfig,
} from '@datorama/akita';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { DRFPaginatedResponse } from '@dendra/utils';
import { map } from 'rxjs';

export type Report = {
  id: number;
  title: string;
  collection: number;
  report_link: string;
  created_at: string;
};

interface ReportState extends EntityState<Report, number> {}

@StoreConfig({ name: 'report' })
@Injectable({ providedIn: 'root' })
export class ReportStore extends EntityStore<ReportState> {
  constructor() {
    super();
  }
}

@QueryConfig({
  sortBy: 'created_at',
  sortByOrder: Order.DESC,
})
@Injectable({ providedIn: 'root' })
export class ReportQuery extends QueryEntity<ReportState> {
  protected store: ReportStore;

  constructor() {
    const store = inject(ReportStore);

    super(store);
    this.store = store;
  }
}

@Injectable({ providedIn: 'root' })
export class ReportService extends NgEntityService<ReportState> {
  protected store: ReportStore;
  query = inject(ReportQuery);

  constructor() {
    const store = inject(ReportStore);

    super(store);
    this.store = store;
  }

  getLatestReportForCollection(collection: number) {
    return super
      .get<Report>({
        params: { collection: collection.toString(), limit: '1' },
        mapResponseFn: (res: DRFPaginatedResponse<Report>) => res.results,
      })
      .pipe(map(results => results[0]));
  }
}
