import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthEntity } from '@dendra/entity-auth';
import { ComponentsModule } from '@mydendra/components/components.module';
import { LocationService } from '@mydendra/states/location';
import { supportedInsights } from '@mydendra/utils/insight-categories';
import { EventService } from '@services/states/event';
import { LocationCollectionService } from '@services/states/location-collection';
import { SharedComponentsModule } from '@shared/components';
import { SharedDirectivesModule } from '@shared/directives';
import { filter, forkJoin, map, shareReplay, switchMap } from 'rxjs';
import { ProjectSummaryComponent } from './components/project-summary/project-summary.component';

@Component({
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    ComponentsModule,
    ProjectSummaryComponent,
  ],
})
export class HomePageComponent {
  private authService = inject(AuthEntity);
  private eventService = inject(EventService);
  private locationCollectionService = inject(LocationCollectionService);
  private locationService = inject(LocationService);

  userProfile$ = this.authService.query.userProfile$;
  hasCurrentOrg$ = this.userProfile$.pipe(
    map(profile => Boolean(profile.organisation)),
  );
  collections$ = this.hasCurrentOrg$.pipe(
    filter(Boolean),
    switchMap(() => this.locationCollectionService.getLocationCollections()),
    switchMap(collections =>
      forkJoin(
        collections.map(c =>
          this.locationService.getAvailableInsightsForCollection(c.id).pipe(
            map(locationInsights => ({
              ...c,
              available_categories: Array.from(
                new Set(
                  locationInsights.flatMap(i =>
                    i.available_categories.map(c => c.insight),
                  ),
                ),
              )
                .map(i => supportedInsights[i])
                .filter(Boolean),
            })),
          ),
        ),
      ),
    ),
    shareReplay(1),
  );

  events$ = this.hasCurrentOrg$.pipe(
    filter(Boolean),
    switchMap(() => this.eventService.getRecentEvents()),
    shareReplay(1),
  );
}
