<div class="home__main">
  <div class="home__header">
    <h2 class="heading-1">Projects</h2>
    <div
      *ifHasFlag="'show-resos-beta-tags'"
      class="flex-fill h-100 d-flex justify-content-end align-items-center p-3"
    >
      <div>
        <mydendra-status
          text="Beta"
          type="info"
          theme="outline"
          size="sm"
        ></mydendra-status>
      </div>
    </div>
  </div>
  @if (collections$ | async; as collections) {
    <div class="project-summary__cards" data-test-id="home__project-cards">
      @for (project of collections; track project) {
        <home-project-summary-card
          [project]="project"
        ></home-project-summary-card>
      }
    </div>
  } @else {
    <div class="d-flex justify-content-center text-center">
      @if ((hasCurrentOrg$ | async) === false) {
        <p>Please select an organisation</p>
      }
      @if (hasCurrentOrg$ | async) {
        <mydendra-loading [in_container]="false"></mydendra-loading>
      }
    </div>
  }
  <div *ifHasFlag="'activity'">
    <div class="home__header mt-4 justify-content-between">
      <h3 class="heading-3">Recent Activities</h3>
      <a [routerLink]="['/activity']">View all Activities</a>
    </div>
    @if (events$ | async; as events) {
      <div class="event__cards">
        @for (event of events; track event) {
          <mydendra-event-card [event]="event"></mydendra-event-card>
        }
      </div>
    } @else {
      <div class="d-flex justify-content-center text-center">
        @if ((hasCurrentOrg$ | async) === false) {
          <p>Please select an organisation</p>
        }
        @if (hasCurrentOrg$ | async) {
          <mydendra-loading [in_container]="false"></mydendra-loading>
        }
      </div>
    }
  </div>
</div>
